import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'

const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`



function InteractiveWhiteboardsPage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="Touch based and Pen Based Intractive White Board Technologies"
        description="WYN Technologies have the best interactive whiteboard technology solutions custom to universities, schools, and office spaces. Share your requirement and get a quote from us today."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Interactive Whiteboards</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Interactive Whiteboards</GroceryTitle>
              <p>We’ve all experienced interactive whiteboards at some point! Whether used in educational environments, or for presentations in office spaces, these tools have become a ubiquitous part of our everyday life. However, the practical applications for this technology extend far beyond the conference table or classroom. Interactive whiteboards have quickly become critical assets in retail and medical sectors.</p>
              <p>
                Wyn Technologies proudly offers interactive whiteboard sales and installation services for universities, schools, and office spaces. We provide start-to-finish setup, installation, and orientation to get you and your team up and running!
              </p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">What We Provide with Our Interactive Whiteboard Installation Services</GroceryIndustryTitle>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Sales, Installation, Product Setup, & Orientation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p><strong>WYN Technologies</strong> is thrilled to be able to provide cutting-edge interactive whiteboard products from leading brands like Sharp and NEC. But we’re not just out to sell you a product; we’re really here to deliver complete turnkey solutions for you! <strong>WYN</strong> provides start-to-finish installation and setup services; our clients can testify to the quality and thoroughness of our workmanship. And once your interactive whiteboards are put in, we aren’t done yet. We’ll walk you and your team through comprehensive product orientation and operating procedures. We’ll take the time to answer your questions, too; we leave nothing to chance!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Site Survey & Professional Recommendations</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Before <strong>WYN</strong> recommends any interactive whiteboard solution, we’ll start by seeking to learn more about your organization and listening to your goals. And one of the best ways for us to learn about your particular needs is to have a technician or two come out and do a physical walkthrough of your property and any intended whiteboard installation locations. After our survey, we’ll share our observations and insights based upon what we observe about your facility. Then we’ll be able to make some professional recommendations about the best interactive whiteboard solutions that will suit both your needs and your budget.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Complete, No-Obligation Product Demos </GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Perhaps you’ve witnessed interactive whiteboards being used in other businesses or classrooms, but you’ve never had the opportunity to try out an interactive whiteboard yourself. No worries, <strong>WYN Technologies</strong> will be glad to give you that opportunity! If you’re new to interactive whiteboards, or just want to learn more about the features and benefits of a particular product, we can let you experience a full product demo. We’ll show you what all an interactive whiteboard can really do, and we’ll give you some good tips about how you can take full advantage of this powerful technology for your business or organization. We’re here to help!</p>
                 {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Ongoing Support When You Need It</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Have you ever had a commercial or educational technology provider leave you feeling like you were all alone and on your own after they put in your equipment? That can be an incredibly frustrating experience! Thankfully, you won’t experience that feeling with <strong>WYN Technologies</strong>! We’ll be here whenever you may need us, and we’re also happy to offer a “service after the sale” contract to make sure everything is kept in good order and continues working well for you long into the future. In short, whenever you have an issue or problem, just give us a call; we’re available 24/7!</p>
               {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              {/* <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle> */}
              <MarkdownContent>
                <h2>Using Interactive Whiteboards</h2>
                <p>Interactive whiteboards utilize several different technologies depending on the type, but usually either come as a standalone system or as an accessory connected to a computer to use.  These setups have a wide variety of functions, but are often used for:</p>
                <ul>
                  <li>Running programs and software loaded onto a connected PC</li>
                  <li>Taking notes via a graphics tablet</li>
                  <li>Controlling a PC from the whiteboard</li>
                  <li>Employing an Audience Response System (ARS) for presentations, allowing attendees to conduct live polls, quizzes and provide feedback that can streamline efficiency</li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Common Types of Interactive Whiteboards</h2>
                <p>Interactive whiteboards come in an array of varying sizes that utilize different forms of technology in order to function. Traditionally, there are various types available in the market today:</p>

                <ul>
                  <li>
                    <strong>Infrared scan (IR Touch)</strong>
                    <p>The infrared interactive touch whiteboard is a device that connects to a computer or projector, allowing the users’ pen, finger, or pointing device to move over a projected image that captures its interference with infrared light. The software then uses x and y coordinates generated by this interference to triangulate the location of this pointing device. With infrared scan technology, any flat surface can function as a whiteboard.</p>
                  </li>
                  <li>
                    <strong>Resistive Touch-based</strong>
                    <p>With resistive touch-based systems, the device’s screen is made of a thin membrane that’s stretched across a conductive backplate. A pointing device can then be pressed against this membrane, forcing it to contact the backplate. The backplate sensor is very accurate and can even discern the amount of pressure generated from the pointing device, giving this system pressure sensitivity capabilities that aren’t present in other whiteboard technology.</p>
                  </li>
                  <li>
                    <strong>Electromagnetic Pen-based Interactive Whiteboard</strong>
                    <p>This system involves a wide array of tiny wires embedded behind the board surface interacting with a coil located in the stylus tip. This interaction generates x and y coordinates that determine the location of the stylus on the screen. Electromagnetic pen-based technology is commonly seen in graphics tablet technology used by digital artists and graphic designers.</p>
                  </li>
                  <li>
                    <strong>Portable Ultrasonic IR Pen-based Interactive Whiteboard</strong>
                    <p>This technology measures infrared light and ultrasound generated by a stylus to compute the time difference between the speed of light and sound. A portable IR device generally consists of a bracket system that connects to a standard whiteboard and connects to a PC or projector.</p>
                  </li>
                  <li>
                    <strong>A Wiimote/IR-based Interactive Whiteboard</strong>
                    <p>Yes. You read that right! This system takes advantage of the technology present in Nintendo Wii gaming systems and utilizes infrared light emitting from a Wii remote control to determine the pointer location. The ability to use readily available technology makes this system one of the most cost-effective choices for Interactive Whiteboards.</p>
                  </li>
                  <li>
                    <strong>A Virtual Whiteboard Via an Interactive Projector</strong>
                    <p>An interactive projector system uses a built-in camera that produces both the image and detects the position of the IR light pen simultaneously. And like other whiteboard systems that use infrared technology, it is subject to line of sight issues and does not have intuitive mouse-like capabilities.</p>
                  </li>
                </ul>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Uses in the Classroom</h2>
                <p>Interactive digital boards for teaching have replaced many traditional analog whiteboard setups over the last few decades and have even superseded the need for outdated DVD’s and media players found in school systems. To put it simply: whiteboards aren’t just for solving algebra equations anymore! These advances now give classrooms the ability to connect and network whiteboards across the internet for online classes and teleconferences. Some companies are even developing interactive educational material specifically designed to be used by interactive whiteboards!</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Implementation of Interactive Whiteboards</h2>
                <p>Since configuration is straightforward, most interactive whiteboard systems can be installed in a reasonable timeframe. In fact, a customer can set one up in only a few hours. Additionally, many interactive whiteboard systems operate as standalone technology that doesn’t require additional wiring or programming knowledge.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>WYN Technologies Can Help Install Your New Interactive Whiteboard</h2>

                <p>WYN Technologies is proud to offer consultation, sales, and installation of a wide range of the best smart boards for business offices and classrooms. We also provide no-obligation product demos to help prospective clients understand better all the features and benefits these devices can provide.</p>
                <p>Additionally, we’re going to be there for you before, during, and after installation! WYN Technologies is happy to offer a “service after the sale” contract to keep your technology maintained and running long after installation.</p>

                <p>WYN stands for ‘Whatever You Need,’ and that’s exactly what we’re here to provide! We know that having a comprehensive knowledge of IT-related systems, a necessary skill in this technological age, but it isn’t everything! That’s why we operate by maintaining a clear moral focus while delivering innovative solutions that are budget-friendly for the everyday consumer.</p>

           <p>Our specialists can help you find solutions to whatever tech-based issue you may have. Give us a call today at <a href="tel:3368990555">(336) 899-0555</a> and let us help you!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default InteractiveWhiteboardsPage
